<template>
  <Loader-component :show="this.show_loader">
    <template v-slot:activity>
      <h5>{{ this.loader_message }}</h5>
    </template>
  </Loader-component>

  <ChangePassword
    v-if="this.force_pwchange"
    @close="this.close_pwchange"
  ></ChangePassword>

  <router-view />
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import ChangePassword from "@/components/ChamgePasswordComponent.vue";

import { useAuthStore } from "@/store/useAuthStore";
import { useRoomStore } from "@/store/useRoomStore";
//import { socket } from '@/fragments/socket'

import { axiosurl } from "@/fragments/axoisurl";
const axios = require("axios");

//import M from "materialize-css";

const localStorage = window.localStorage;

export default {
  name: "App",

  components: {
    LoaderComponent,
    ChangePassword,
  },

  created() {},

  mounted() {
    document.addEventListener("csrf_got", () => {
      //console.log('CSRF GOT!'); // Prints "Example of an event"
    });

    document.addEventListener("autologin_done", () => {
      this.loader_message = "";
      this.show_loader = false;
    });

    if (!localStorage.getItem("refreshtoken")) {
      this.show_loader = false;
    }

    //useAuthStore().try_autologin();

    useRoomStore();
  },

  computed: {
    force_pwchange() {
      return useAuthStore().user.forced_pwchange;
    },
  },

  methods: {
    show_load(data) {
      (this.show_loader = false), (this.loader_message = data.message);
    },

    hide_load() {
      (this.show_loader = false), (this.loader_message = "");
    },

    close_pwchange(data) {
      if (data.status == 0) {
        this.logout();
      }
      if (data.status == 1 && this.force_pwchange) {
        this.logout();
      }
      if (data.status == 1 && !this.force_pwchange) {
        useAuthStore().user.forced_pwchange = false;
      }
    },

    logout() {
      this.show_loader = true;
      axios
        .post(
          axiosurl + "/logout",
          {},
          {
            withCredentials: true,
          }
        )
        .then(() => {
          this.drawer = false;
          this.show_loader = false;
          useAuthStore().logout();
          this.$router.push("/");
        });
    },
  },

  data() {
    return {
      show_loader: true,
      loader_message: "Auto login",
    };
  },
};
</script>

<style></style>
