import { defineStore } from 'pinia'

//import { jwtDecode } from "jwt-decode"

//import { axiosurl } from '@/fragments/axoisurl'
//const axios = require('axios')

//import {socket} from '@/fragments/socket'

//const localStorage = window.localStorage
//localStorage.setItem('refreshtoken', res.data.ref_token)

export const useRoomStore = defineStore('roomstore', {
    
    state: () => ({
        roomname: '',
        roomid: '',
        mateid: '',
        had_event: false
    }),

    getters: {
        /*
        had_event(){
            if((!this.roomname || this.roomname == '') || (!this.roomid || this.roomid == '') || (!this.mateid|| this.mateid=='')){
                return false
            }
            return true
        }
        */
    },

    actions: {
        _init(){
            this.roomname = ''
            this.roomid = ''
            this.mateid = ''
            this.had_event = false
        }
    },

})