<template>
  <div class="modalback">
    <ErrorComponent
      v-if="this.show_error"
      :message="this.errormessage"
      @close="this.close_error"
    >
    </ErrorComponent>

    <div class="carousel_item">
      <v-icon
        icon="fas fa-xmark"
        :color="'var(--darker_red)'"
        size="large"
        style="position: absolute; top: 10px; right: 10px; cursor: pointer"
        @click.stop="this.close_change"
      >
      </v-icon>

      <p
        class="signuptitle"
        v-if="!this.succeed"
        style="font-family: arimo; font-weight: 600; margin-top: 20px"
      >
        {{
          langs.changepassword.title[this.def_lang] ?? langs.changepassword.title.fallback
        }}
      </p>

      <p
        class="signuptitle"
        v-if="this.succeed"
        style="font-family: arimo; font-weight: 600; margin-top: 20px"
      >
        {{
          langs.changepassword.changed[this.def_lang] ??
          langs.changepassword.changed.fallback
        }}
      </p>

      <p v-if="this.succeed">
        {{
          langs.changepassword.succeed1[this.def_lang] ??
          langs.changepassword.succeed1.fallback
        }}
      </p>

      <p v-if="this.succeed">
        {{
          langs.changepassword.succeed2[this.def_lang] ??
          langs.changepassword.succeed2.fallback
        }}
      </p>

      <v-text-field
        v-if="!this.succeed"
        v-model="this.old_password"
        variant="underlined"
        flat
        class="m-0 mt-4 w-[90%] lg:w-[60%]"
        :type="this.show_oldpassword ? 'text' : 'password'"
      >
        <template v-slot:label>
          <div>
            {{
              langs.changepassword.old_pw[this.def_lang] ??
              langs.changepassword.old_pw.fallback
            }}<span style="color: red">*</span>
          </div>
        </template>

        <template v-slot:append-inner>
          <v-icon
            :icon="this.show_oldpassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
            size="small"
            @click.stop="this.show_oldpassword = !this.show_oldpassword"
          ></v-icon>
        </template>
      </v-text-field>

      <v-text-field
        v-if="!this.succeed"
        v-model="this.new_password"
        variant="underlined"
        flat
        class="m-0 mt-4 w-[90%] lg:w-[60%]"
        :type="this.show_newpassword ? 'text' : 'password'"
      >
        <template v-slot:label>
          <div>
            {{
              langs.changepassword.new_pw1[this.def_lang] ??
              langs.changepassword.new_pw1.fallback
            }}<span style="color: red">*</span>
          </div>
        </template>

        <template v-slot:append-inner>
          <v-icon
            :icon="this.show_newpassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
            size="small"
            @click.stop="this.show_newpassword = !this.show_newpassword"
          ></v-icon>
        </template>
      </v-text-field>

      <v-text-field
        label="Confirm password"
        v-if="!this.succeed"
        v-model="this.password_confirmation"
        variant="underlined"
        flat
        class="m-0 mt-4 w-[90%] lg:w-[60%]"
        :type="this.show_pw_confirm ? 'text' : 'password'"
      >
        <template v-slot:label>
          <div>
            {{
              langs.changepassword.new_pw2[this.def_lang] ??
              langs.changepassword.new_pw2.fallback
            }}<span style="color: red">*</span>
          </div>
        </template>

        <template v-slot:append-inner>
          <v-icon
            :icon="this.show_pw_confirm ? 'fas fa-eye-slash' : 'fas fa-eye'"
            size="small"
            @click.stop="this.show_pw_confirm = !this.show_pw_confirm"
          >
          </v-icon>
        </template>
      </v-text-field>

      <v-btn
        class="blue-button"
        style="margin-top: 20px"
        @click.stop="this.submit_change"
        v-if="!this.succeed"
      >
        {{ langs.buttons.change[this.def_lang] ?? langs.buttons.change.fallback }}
      </v-btn>

      <v-btn
        class="button"
        style="margin-top: 20px"
        @click.stop="this.done"
        v-if="this.succeed"
      >
        {{ langs.buttons.comprehend[this.def_lang] ?? langs.buttons.comprehend.fallback }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { langs } from "@/fragments/langfiles/changepassword.js";

import ErrorComponent from "./ErrorComponent.vue";
import { useAuthStore } from "@/store/useAuthStore";

import { axiosurl } from "@/fragments/axoisurl";
const axios = require("axios");

export default {
  name: "ChangePasswordComponent",

  emits: ["close", "show_loader", "hide_loader"],

  components: {
    ErrorComponent,
  },

  props: {},

  created: function () {},

  mounted() {},

  computed: {
    def_lang() {
      return useAuthStore().get_lang;
    },
  },

  methods: {
    close_error() {
      this.show_error = false;
      this.errormessage = "";
    },

    close_change() {
      this.succeed
        ? this.$emit("close", { status: 1 })
        : this.$emit("close", { status: this.status });
    },

    submit_change() {
      if (!this.old_password || !this.new_password || !this.password_confirmation) {
        this.errormessage = "All fields must be filled!";
        this.show_error = true;
        return;
      }

      if (this.new_password !== this.password_confirmation) {
        this.errormessage = "New password and it's confirmation must match!";
        this.show_error = true;
        this.old_password = "";
        this.new_password = "";
        this.password_confirmation = "";
        return;
      }

      this.$emit("show_loader", { activity: "POST NEW PASSWORD" });

      let headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": useAuthStore().get_csrf,
        Authorization: "Bearer " + useAuthStore().access_token,
      };

      let data = {
        old_password: this.old_password,
        new_password: this.new_password,
        confirmation: this.password_confirmation,
      };

      axios
        .post(axiosurl + "/change_password", data, {
          withCredentials: true,
          headers: headers,
        })
        .then(() => {
          this.$emit("hide_loader");
          this.succeed = true;
          this.status = 1;
        })
        .catch((e) => {
          this.$emit("hide_loader");
          this.errormessage = e.response.data.message;
          this.show_error = true;
          this.old_password = "";
          this.new_password = "";
          this.password_confirmation = "";
          return;
        });
    },

    done() {
      if (this.succeed) {
        this.$emit("close", { status: 1 });
      }
    },
  },

  data() {
    return {
      succeed: false,
      show_oldpassword: false,
      old_password: "",
      show_newpassword: false,
      new_password: "",
      show_pw_confirm: false,
      password_confirmation: "",

      status: 0,

      show_error: false,
      errormessage: "",

      //LANGUAGE PACK
      langs: langs,
    };
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .carousel_item {
    width: 70vw !important;
  }

  .signuptitle {
    font-size: calc(1rem + 2vw);
  }
}

@media screen and (min-width: 601px) {
  .carousel_item {
    width: 17vw !important;
  }

  .signuptitle {
    font-size: calc(1rem + 1vh);
  }
}

.modalback {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.9);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
}

.carousel_item {
  /*backdrop-filter: blur(5px) brightness(30%);*/
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  max-height: 70% !important;
  box-shadow: var(--design_blue_shadow);
  border: 1px solid var(--design_blue);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}

.inputboxes {
  width: 80% !important;
  padding: 0 !important;
  margin: 10px;
}
</style>
