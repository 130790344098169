<template>

    <div class="global-loadback" v-if="this.show">

        <div>

            <div
            style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items:first baseline">

                <h5 class="t">
                    <slot name="activity">
                        LOADING
                    </slot>
                </h5>

                <span class="spin-container">

                    <div class="spin spin1" style=""></div>
                    <div class="spin spin2" style=""></div>
                    <div class="spin spin3" style=""></div>

                </span>
            
            </div>

        
        </div>

    </div>

</template>

<script>

import M from 'materialize-css'

export default{

    beforeUnmount(){
        M.AutoInit()
    },

    name: 'Loader_component',

    props: {
        show: Boolean,
    },

    mounted(){
    },

    methods: {
    },

    data(){return{
    }}

}

</script>

<style scoped>

    @keyframes load {
        0%{
            opacity: 0.08;
            font-size: 5px;
            font-weight: 400;
            filter: blur(10px);
            letter-spacing: 10vh;
        }
        100%{
            opacity: 1;
            font-size: 6vh;
            font-weight:800;
            letter-spacing: 5px;
            }
    }

    @keyframes bounce{

        0%{
            transform: translateY(5px) scaleY(.9);
        }

        100%{
            transform: translateY(-30px) scaleY(1.1);
        }

    }

    .spin{
        height: 20px;
        width: 20px;
        /*background-image: url('@/assets/img/kb_blue.png');*/
        background-image: url('@/assets/img/loader/kettlebell-c.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 20px;
        margin-left: 5px;
    }

    .spin1{
        animation: bounce .6s infinite 0s ease-in-out;
        animation-direction: alternate;
    }

    .spin2{
        animation: bounce .6s infinite .3s ease-in-out;
        animation-direction: alternate;
    }

    .spin3{
        animation: bounce .6s infinite .6s ease-in-out;
        animation-direction: alternate;
    }



    .spin-container{
        display: flex;
        flex-direction: row;
        margin-left: 10px;
    }

    .load_anim {
        position: absolute;
        display:flex;
        justify-content: center;
        align-items: center;
        /*height:100%;*/
    /* 	width: 350px; */
    /* 	font-size:26px; */
        
        animation: load 1.2s infinite 0s linear;
        animation-direction: alternate;
        /*text-shadow: 0 0 5px var(--design_blue);*/
        
    }

    .t{ 
        display: inline;
        font-family: plex;
        color: var(--design_blue);
    }

    .global-loadback{
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        height: 100vh;
        width: 100vw;
        overflow: hidden !important;

        backdrop-filter: blur(10px) brightness(10%);

        z-index: 9999;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

</style>