import { reactive } from "vue"
import { io } from "socket.io-client"
import { axiosurl } from '@/fragments/axoisurl.js'

import { useAuthStore } from "@/store/useAuthStore"
import { useRoomStore } from "@/store/useRoomStore";


export const state = reactive({
  connected: false,
  roomevents: [],
})

export const conn_state = reactive({
  connected: false,
})

export const socket = io(axiosurl, 
  {
    withCredentials: true,
    autoConnect: true,
    forceNew: true
  })


socket.on("connect", () => {
  state.connected = true
  conn_state.connected = true
  //console.log('CONNECTED!')
  if(useRoomStore().had_event){
    //console.log('TRYING RECONNECT AT CONNECTION ETABLISHMENT!')
    socket.emit("TRY_RECONNECT", {roomid: useRoomStore().roomid, mateid: useRoomStore().mateid})
  }
})

socket.on("disconnect", () => {
  //console.log('SOCKET DISCONNECTED!')
  state.connected = false
  conn_state.connected = false
})

socket.on('CSRF_EXCHANGE', data => {
  let event = new CustomEvent("csrf_got", {})
  document.dispatchEvent(event)
  //console.log('CSRF GOT!: ', data.CSRF)
  useAuthStore().csrf = data.CSRF
  if(useRoomStore().had_event){
    socket.emit("TRY_RECONNECT", {roomid: useRoomStore().roomid, mateid: useRoomStore().mateid})
    return
  } else{
    useAuthStore().try_autologin()
    return
  }
  
})

socket.on("RECONNECT_STATUS", data => {
  if(data.status === 1){
    document.dispatchEvent(new CustomEvent("reconnect_sucess", {}))
  }
})

/*
socket.on('TOKENSET_REFRESH', data => {
  useAuthStore().ready = true
  useAuthStore().set_tokenset(data)
})
*/

