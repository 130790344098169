const langs = {

    buttons: {
        change: {
            fallback: 'CHANGE',
            hun: 'MEGVÁLTOZTATÁS',
            eng: 'CHANGE',
            de: 'Veränderung',
            fra: 'CHANGER',
            ita: 'CAMBIARE',
            spa: 'CAMBIAR',
            pol: 'ZMIANA',
            swe: 'ÄNDRA'
        },
        comprehend: {
            fallback: 'COMPREHEND',
            hun: 'MEGÉRTETTEM',
            eng: 'COMPREHEND',
            de: 'Verstehen',
            fra: 'COMPRENDRE',
            ita: 'COMPRENDERE',
            spa: 'COMPRENDER',
            pol: 'ROZUMIEĆ',
            swe: 'FÖRSTÅ'
        }
    },

    changepassword:{
        title: {
            fallback: 'CHANGE PASWORD',
            hun: 'JELSZÓ MEGVÁLTOZTATÁSA',
            eng: 'CHANGE PASWORD',
            de: 'Passwort ändern',
            fra: 'CHANGER LE MOT DE PASSE',
            ita: 'CAMBIA LA PASSWORD',
            spa: 'CAMBIAR CONTRASEÑA',
            pol: 'ZMIANA HASŁA',
            swe: 'BYT LÖSENORD'
        },
        changed: {
            fallback: 'PASSWORD CHANGED!',
            hun: 'A JELSZÓ MEGVÁLTOZOTT!',
            eng: 'PASSWORD CHANGED!',
            de: 'Passwort geändert',
            fra: 'MOT DE PASSE CHANGÉ !',
            ita: 'PASSWORD CAMBIATA!',
            spa: '¡CONTRASEÑA CAMBIADA!',
            pol: 'ZMIANA HASŁA!',
            swe: 'LÖSENORDET ÄNDRAT!'
        },
        succeed1: {
            fallback: 'Your password has been changed!',
            hun: 'A jelszava megváltozott!',
            eng: 'Your password has been changed!',
            de: 'Ihr Passwort wurde geändert!',
            fra: 'Votre mot de passe a été changé !',
            ita: 'La tua password è stata cambiata!',
            spa: '¡Tu contraseña ha sido cambiada!',
            pol: 'Twoje hasło zostało zmienione!',
            swe: 'Ditt lösenord har ändrats!'
        },
        succeed2: {
            fallback:'You will be logged out. You can login again with your new password.',
            hun: 'A rendszer kilépteti. Az új jelszavával be tud jelentkezni.',
            eng: 'You will be logged out. You can login again with your new password.',
            de: 'Sie werden ausgeloggt. Sie können sich erneut mit Ihrem neuen Passwort anmelden.',
            fra: 'Vous serez déconnecté. Vous pouvez vous connecter à nouveau avec votre nouveau mot de passe.',
            ita: 'Verrai disconnesso. Puoi effettuare il login nuovamente con la tua nuova password.',
            spa: 'Serás desconectado. Puedes iniciar sesión nuevamente con tu nueva contraseña.',
            pol: 'Zostaniesz wylogowany. Możesz ponownie zalogować się swoim nowym hasłem.',
            swe: 'Du kommer att loggas ut. Du kan logga in igen med ditt nya lösenord.'
        },
        old_pw:{
            fallback: 'Old password:',
            hun: 'Régi jelszó:',
            eng: 'Old password:',
            de: 'Altes Passwort:',
            fra: 'Ancien mot de passe :',
            ita: 'Vecchia password:',
            spa: 'Contraseña anterior:',
            pol: 'Stare hasło:',
            swe: 'Gammalt lösenord:'
        },
        new_pw1: {
            fallback: 'New password:',
            hun: 'Új jelszó:',
            eng: 'New password:',
            de: 'Neues Passwort:',
            fra: 'Nouveau mot de passe :',
            ita: 'Nuova password:',
            spa: 'Nueva contraseña:',
            pol: 'Nowe hasło:',
            swe: 'Nytt lösenord:'
        },
        new_pw2: {
            fallback: 'Confirm password:',
            hun: 'Új jelszó mégegyszer:',
            eng: 'Confirm password:',
            de: 'Passwort bestätigen:',
            fra: 'Confirmer le mot de passe :',
            ita: 'Conferma password:',
            spa: 'Confirmar contraseña:',
            pol: 'Potwierdź hasło:',
            swe: 'Bekräfta lösenord:'
        },
    }
}

export {langs}
