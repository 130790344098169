/* eslint-disable */
import './assets/css/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
const pinia = createPinia()

//import Vue3Marquee from 'vue3-marquee'

import App from './App.vue'
import router from './router'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import '@fortawesome/fontawesome-free/css/all.css'
import { aliases, fa } from 'vuetify/iconsets/fa'

import VueMobileDetection from "vue-mobile-detection"

//import 'materialize-css/dist/css/materialize.min.css'


const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa
    }
  }
})


createApp(App)
.use(pinia)
.use(router)
.use(vuetify)
.use(VueMobileDetection)
//.use(Vue3Marquee)
.mount('#app')
