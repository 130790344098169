<template>
    <div class="error-container">
        <div class="modal-container">
            <v-icon
            icon="fas fa-xmark"
            size="small"
            class="closeicon"
            @click.stop="this.close">
            </v-icon>
            <p>{{ this.message }}</p>
        </div>
    </div>
</template>
  
<script>
  export default {
    name: 'ErrorComponent',
  
    emits: [
        'close'
    ],
  
    components: {},
  
    props: {
        message: String,
    },
  
    created: function () {},
  
    mounted() {
        setTimeout( this.close, 10000 )
    },
  
    computed: {},
  
    methods: {
        close(){
            this.$emit('close')
        },
    },
  
    data() {
      return {}
    }
  }
</script>
  
<style scoped>
.error-container{
    position: fixed;
    bottom: 3%;
    left: 0;
    width: 100%;
    z-index: 8888;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container{
    position: relative;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--darker_red_70);
    border: 2px solid var(--darker_red);
    border-radius: 5px;
    max-width: 80%;
}

.closeicon{
    position: absolute;
    top: 0;
    right: 0;
}

</style>
  