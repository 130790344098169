import { defineStore } from 'pinia'

import { jwtDecode } from "jwt-decode"

import { axiosurl } from '@/fragments/axoisurl'
const axios = require('axios')

import {socket} from '@/fragments/socket'

const localStorage = window.localStorage
//localStorage.setItem('refreshtoken', res.data.ref_token)

export const useAuthStore = defineStore('authentication', {
    
    state: () => ({
        csrf: '',
        user: {},
        owned_workouts: [],
        taken_names: [],
        refresh_token: '',
        access_token: '',
        refresh_time: 0,
        max_supervised: 0,
        authenticated: false,
        pref_lang: 'eng',
        ready: false,
        billing_address: ''
    }),

    getters: {

        get_lang: state => {
            return state.pref_lang ?? 'eng'
        },

        get_csrf: state => {
            return state.csrf
        },

        get_readystate: state => {
            return state.ready
        },

        get_id: state => {
            return state.user._id
        },

        get_plan: state => {
            state
            let roles = [
                'IB',
                'IP',
                'TB',
                'TP',
                'C3',
                'C6',
                'C10',
                'FREE',
                'SUPER'
            ]
            roles
            return state.user.plan
        }

    },

    actions: {

        set_lang(lang){
            this.pref_lang = lang
        },

        set_csrf(csrf){
            this.csrf = csrf
        },

        set_tokenset(data){
            this.refresh_token = data.tokenset.refreshtoken
            localStorage.setItem('refreshtoken', this.refresh_token)
            this.access_token = data.tokenset.accesstoken
            this.user = data.user
            this.pref_lang = data.user.pref_lang
            this.owned_workouts = data.owned_workouts

            if (['IB', 'IP', 'FREE'].includes(this.user.plan)){
                this.max_supervised = 0
            } else if (['TB', 'C3'].includes(this.user.plan)){
                this.max_supervised = 3
            } else if (['TP', 'C6'].includes(this.user.plan)){
                this.max_supervised = 6
            } else if (['C10', 'SUPER'].includes(this.user.plan)){
                this.max_supervised = 10
            }

            this.get_taken_names()

            this.billing_address = data.billing_address
            
            this.authenticated = true
            let refreshtime = jwtDecode(this.access_token)
            this.refresh_time = parseInt(refreshtime.refresh_time)
            setTimeout(this.silent_refresh, this.refresh_time)
            let event = new CustomEvent("login_done", {})
            document.dispatchEvent(event)
        },

        silent_refresh(){
            if(this.refresh_token){

                let socketid = socket.id

                let headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken':  this.csrf
                }

                let data = {
                    refresh_token: this.refresh_token,
                    socketid: socketid,
                }

                axios.post(
                    axiosurl + '/refresh',
                    data,
                  { 
                      withCredentials: true,
                      headers: headers
                  }
                  )
                  .then( (resp) => {
                    let data = {
                        tokenset: resp.data.tokenset,
                        user: resp.data.user
                    }
                    //console.log('SILENT REFRESH!')
                    //console.log(data)
                    this.set_tokenset(data)
                      //this.set_data(resp.data.access, resp.data.refresh)
                  })
                  .catch( err => {
                      //console.log(err)
                      err
                      this.logout()
                  })

              }
        },

        fetch_workouts(){
            let headers = {
                "Content-Type": "application/json",
                "X-CSRFToken": this.get_csrf,
                Authorization: "Bearer " + this.access_token,
              };
        
              axios
                .get(axiosurl + "/get_owned_wos", { headers: headers })
                .then((res) => {
                  this.taken_names = res.data.taken_names
                  this.owned_workouts = res.data.workouts
                })
                .catch((err) => {
                  //console.log(err);
                  err
                });
        },

        get_taken_names() {
            let headers = {
              "Content-Type": "application/json",
              "X-CSRFToken": this.get_csrf,
              Authorization: "Bearer " + this.access_token,
            };
      
            axios
              .get(axiosurl + "/get_taken_wonames", { headers: headers })
              .then((res) => {
                this.taken_names = res.data.taken_names
              })
              .catch((err) => {
                //console.log(err);
                err;
              });
        },

        try_autologin(){

            if(!localStorage.getItem('refreshtoken')){
                this.ready = true
                return
            }

            let headers = {
                'Content-Type': 'application/json',
                'X-CSRFToken':  this.csrf
            }

            let data = {
                refresh_token: localStorage.getItem('refreshtoken')
            }

            /*
            if(!this.localStorage.getItem('refreshtoken')){
                console.log('NO REFRESH TOKEN!')
                let event = new CustomEvent("autologin_done", {})
                document.dispatchEvent(event)
                return
            }
            */

            axios.post(
                axiosurl + '/refresh',
                data,
                {
                    withCredentials: true,
                    headers: headers
                }
            )
            .then( (resp) => {
                let d = {
                    tokenset: resp.data.tokenset,
                    user: resp.data.user,
                    owned_workouts: resp.data.owned_workouts
                }
                this.set_tokenset(d)
                let event = new CustomEvent("autologin_done", {})
                document.dispatchEvent(event)
            })
            .catch( err => {
                this.ready = true
                //console.log(err)
                err
                this.logout()
                let event = new CustomEvent("autologin_done", {})
                document.dispatchEvent(event);
            })
        },

        logout(){
            this.user = {}
            this.refresh_token = ''
            this.access_token = ''
            this.refresh_time = 0
            this.authenticated = false
            this.pref_lang = 'eng',
            localStorage.removeItem('refreshtoken')
        }

    },

})