import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: "/register",
    name: "register",
    component: function() {
      return import("@/views/RegisterView.vue")
    }
  },
  {
    path: "/login",
    name: "login",
    component: function() {
      return import("@/views/LoginView.vue")
    }
  },
  {
    path: "/success",
    name: "success",
    component: function(){
      return import("@/views/SuccessView.vue")
    }
  },
  {
    path: "/index",
    name: "index",
    component: function() {
      return import("@/views/IndexView.vue")
    }
  },
  {
    path: "/demo",
    name: "demo",
    component: function() {
      return import("@/views/DemoView.vue")
    }
  },
  {
    path: "/single",
    name: "single",
    component: function() {
      return import("@/views/SingleView.vue")
    }
  },
  {
    path: "/supervisor",
    name: "supervisor",
    component: function() {
      return import("@/views/SupervisorView.vue")
    }
  },
  {
    path: "/supervised",
    name: "supervised",
    component: function() {
      return import("@/views/SupervisedView.vue")
    }
  },
  {
    path: "/spectator",
    name: "spectator",
    component: function() {
      return import("@/views/SpectatorView.vue")
    }
  },
  {
    path: '/carousel',
    name: 'carousel',
    component: function() {
      return import("@/views/CarouselView.vue")
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: function() {
      return import("@/views/PaymentView.vue")
    }
  },
  {
    path: '/workout_manager',
    name: 'womanager',
    component: function() {
      return import("@/views/WOmanagerView.vue")
    }
  },
  {
    path: '/result_manager',
    name: 'resultmanger',
    component: function() {
      return import("@/views/ResultsView.vue")
    }
  },
  {
    path: "/",
    name: "home",
    component:
    function() {
      return import("@/views/WelcomeView.vue")
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router


